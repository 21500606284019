import { gql } from "@apollo/client";
import { CoverImageFragment } from "@src/fragments.generated";
import {
  JumpToEmployeeFragment,
  useJumpToLibraryItemsQuery,
  useJumpToLocationGroupsQuery,
  useJumpToLocationsQuery,
  useJumpToPeopleQuery,
  useJumpToRoleGroupsQuery,
  useJumpToRolesQuery,
  useJumpToTagsQuery,
} from "@components/ui/jumpToBar/hooks/useAllJumpToItems.generated";
import { ContentType } from "@src/deprecatedDesignSystem/components/ContentAvatar";
import { GroupType as GroupTypeType } from "@src/deprecatedDesignSystem/components/GroupAvatar";
import {
  LibraryItemType,
  LibrarySearchMode,
  LocationGroupType,
  UserType,
} from "@src/types.generated";
import { useMemo } from "react";
import { getMediaUrl } from "@src/utils/media";
import { Route } from "nextjs-routes";
import { useFilteredRouteItems } from "./routeItems";

export type BaseJumpToItem = {
  id: string | number;
  name: string;
  searchHeadline?: string | null;
  openedAt?: string | null;
  type: ContentType | GroupTypeType | "Employee" | "Tag" | "route";
  locations?: JumpToEmployeeFragment["locations"] | null;
  coverImage?: CoverImageFragment;
  mediaUrl?: string;
  isSharp?: boolean;
};

export interface EmployeeItem extends BaseJumpToItem {
  email?: string | null;
  phoneNumber?: string | null | undefined;
  userType: UserType;
  locations: JumpToEmployeeFragment["locations"];
  deactivatedAt?: string | null;
}

export interface CourseOrPathItem extends BaseJumpToItem {
  libraryItemId: string;
  coverImage?: CoverImageFragment;
}

export interface LocationItem extends BaseJumpToItem {
  type: "Location";
}

export interface RoleItem extends BaseJumpToItem {
  type: "Role";
}

export interface LocationGroupItem extends BaseJumpToItem {
  type: LocationGroupType;
}

export interface RoleGroupItem extends BaseJumpToItem {
  type: "RoleGroup";
}

export interface SkillItem extends BaseJumpToItem {
  libraryItemId: string;
  emojiIcon: string;
}

export interface TagItem extends BaseJumpToItem {
  availableInApp: boolean;
}

export interface OrgConfigItem extends BaseJumpToItem {
  catalogPathId: string;
}

export interface RouteItem extends BaseJumpToItem {
  route: Route;
  icon: React.ComponentType<{ className?: string }>;
}

export type JumpToBarLibraryItem = CourseOrPathItem | OrgConfigItem;

export type JumpToItem =
  | BaseJumpToItem
  | EmployeeItem
  | CourseOrPathItem
  | OrgConfigItem
  | LocationItem
  | LocationGroupItem
  | RoleGroupItem
  | RoleItem
  | SkillItem
  | TagItem
  | RouteItem;

type Return = {
  items: JumpToItem[];
  loading: boolean;
};

type Options = {
  types?: string[];
  search?: string;
};

const useAllJumpToItems = (opts?: Options): Return => {
  const {
    data: newEmployeeData,
    previousData: previousEmployeeData,
    loading: employeeLoading,
  } = useJumpToPeopleQuery({
    variables: {
      input: {
        includeDeactivated: { value: true },
        search: opts?.search ? { value: opts.search } : undefined,
      },
    },
  });
  const employeeData = newEmployeeData || previousEmployeeData;
  const routeItems = useFilteredRouteItems();

  const { data: libraryItemsData, loading: libraryItemsLoading } =
    useJumpToLibraryItemsQuery({
      fetchPolicy: "no-cache",
      variables: {
        input: {
          search: opts?.search ? { value: opts.search } : undefined,
          sort: { column: "name", descending: false },
          searchMode: LibrarySearchMode.NameDescriptionAndContent,
        },
        pagination: {
          limit: 20,
          offset: 0,
        },
      },
    });
  const { data: locationsData, loading: locationsLoading } =
    useJumpToLocationsQuery();
  const { data: rolesData, loading: rolesLoading } = useJumpToRolesQuery();
  const { data: locationGroupsData, loading: locationGroupsLoading } =
    useJumpToLocationGroupsQuery();
  const { data: roleGroupsData, loading: roleGroupsLoading } =
    useJumpToRoleGroupsQuery();
  const { data: tagsData, loading: tagsLoading } = useJumpToTagsQuery();
  const employees: EmployeeItem[] = useMemo(
    () =>
      employeeData?.Employees?.objects.map((employee) => {
        return {
          id: employee.id,
          name: employee.name || "",
          email: employee.email,
          phoneNumber: employee.phoneNumber,
          userType: employee.userType,
          locations: employee.locations,
          type: "Employee",
          deactivatedAt: employee.deactivatedAt,
        };
      }) || [],
    [employeeData],
  );

  const libraryItems: JumpToBarLibraryItem[] = useMemo(() => {
    const _libraryItems: JumpToBarLibraryItem[] = [];
    libraryItemsData?.LibraryItems.objects.forEach((li) => {
      if (li.type === LibraryItemType.Path && li.path) {
        _libraryItems.push({
          id: li.path.id,
          libraryItemId: li.id,
          searchHeadline: li.searchHeadline,
          type: "Module",
          coverImage: li.coverImage || undefined,
          name: li.name.en,
        });
      } else if (li.type === LibraryItemType.Course && li.course) {
        _libraryItems.push({
          id: li.course.id,
          libraryItemId: li.id,
          type: "Course",
          coverImage: li.coverImage || undefined,
          name: li.name.en,
          searchHeadline: li.searchHeadline,
        });
      } else if (
        li.type === LibraryItemType.TrainingResource &&
        li.trainingResource
      ) {
        _libraryItems.push({
          id: li.trainingResource.id,
          libraryItemId: li.id,
          type: "Resource",
          mediaUrl: li.trainingResource.media
            ? getMediaUrl(li.trainingResource.media, { thumb: true })
            : undefined,
          name: li.name.en,
        });
      } else if (li.type === LibraryItemType.Skill && li.skill) {
        _libraryItems.push({
          id: li.skill.id,
          libraryItemId: li.id,
          type: "Skill",
          coverImage: li.coverImage || undefined,
          name: li.name.en,
          searchHeadline: li.searchHeadline,
        });
      } else if (li.orgSharpConfig) {
        _libraryItems.push({
          id: li.orgSharpConfig.id,
          libraryItemId: li.id,
          type: "PremiumPath",
          isSharp: true,
          coverImage: {
            id: li.orgSharpConfig.id,
          },
          name: "Sexual Harassment Prevention Training",
          searchHeadline: li.searchHeadline,
        });
      } else if (
        li.orgPremiumContentConfig &&
        li.orgPremiumContentConfig.catalogConfig!.catalogPath
      ) {
        const path = li.orgPremiumContentConfig.catalogConfig!.catalogPath.path;
        _libraryItems.push({
          id: li.orgPremiumContentConfig.id,
          type: "PremiumPath",
          catalogPathId:
            li.orgPremiumContentConfig.catalogConfig.catalogPath.id,
          name: path.libraryItem.name.en,
          searchHeadline: li.searchHeadline,
          coverImage: path.libraryItem.coverImage || undefined,
        });
      }
    });
    return _libraryItems;
  }, [libraryItemsData?.LibraryItems.objects]);
  const locationGroups: LocationGroupItem[] = useMemo(
    () =>
      locationGroupsData?.LocationGroups.objects.map((locationGroup) => {
        return {
          id: locationGroup.id,
          name: locationGroup.name,
          type: locationGroup.type,
        };
      }) || [],
    [locationGroupsData],
  );
  const locations: LocationItem[] = useMemo(
    () =>
      locationsData?.Locations.objects.map((x) => {
        return {
          id: x.id,
          name: x.name,
          type: "Location",
        };
      }) || [],
    [locationsData?.Locations.objects],
  );
  const roleGroups: RoleGroupItem[] = useMemo(
    () =>
      roleGroupsData?.RoleGroups.objects.map((roleGroup) => {
        return {
          id: roleGroup.id,
          name: roleGroup.name,
          type: "RoleGroup",
        };
      }) || [],
    [roleGroupsData],
  );
  const roles: RoleItem[] = useMemo(
    () =>
      rolesData?.Roles.objects.map((x) => {
        return {
          id: x.id,
          name: x.name,
          type: "Role",
        };
      }) || [],
    [rolesData?.Roles.objects],
  );
  const tags: TagItem[] = useMemo(
    () =>
      tagsData?.Tags.map((tag) => {
        return {
          id: tag.id,
          name: tag.nameTranslations.en,
          availableInApp: tag.availableInApp,
          type: "Tag",
        };
      }) || [],
    [tagsData],
  );
  const jumpToItems = useMemo(
    () => [
      ...employees,
      ...libraryItems,
      ...locations,
      ...roles,
      ...roleGroups,
      ...locationGroups,
      ...tags,
      ...routeItems,
    ],
    [
      employees,
      libraryItems,
      locationGroups,
      locations,
      roleGroups,
      roles,
      tags,
      routeItems,
    ],
  );

  const filteredItems = useMemo(() => {
    if (!opts?.types) {
      return jumpToItems;
    }
    return jumpToItems.filter((item) => opts!.types!.includes(item.type));
  }, [jumpToItems, opts]);

  return useMemo(
    () => ({
      items: filteredItems,
      loading:
        employeeLoading ||
        libraryItemsLoading ||
        locationsLoading ||
        rolesLoading ||
        roleGroupsLoading ||
        locationGroupsLoading ||
        tagsLoading,
    }),
    [
      filteredItems,
      employeeLoading,
      libraryItemsLoading,
      locationsLoading,
      rolesLoading,
      roleGroupsLoading,
      locationGroupsLoading,
      tagsLoading,
    ],
  );
};

export default useAllJumpToItems;

gql`
  query JumpToPeople($input: GetPeopleInput!) {
    Employees: People(input: $input, pagination: { limit: 20 }) {
      objects {
        ...JumpToEmployee
      }
    }
  }
  query JumpToLibraryItems(
    $input: AdminLibraryInput!
    $pagination: PaginationInput
  ) {
    LibraryItems: AdminLibrary(input: $input, pagination: $pagination) {
      objects {
        id
        type
        course {
          id
        }
        path {
          id
        }
        skill {
          id
        }
        trainingResource {
          id
        }
        name {
          ...TranslationSet
        }
        coverImage {
          ...CoverImage
        }
        trainingResource {
          ...JumpToTrainingResource
        }
        orgPremiumContentConfig {
          ...JumpToOrgPremiumContentConfig
        }
        orgSharpConfig {
          id
        }
        searchHeadline
      }
    }
  }
  fragment JumpToOrgPremiumContentConfig on OrgPremiumContentConfig {
    id
    catalogConfig {
      id
      catalogPath {
        id
        path {
          id
          libraryItem {
            id
            coverImage {
              ...CoverImage
            }
            name {
              ...TranslationSet
            }
            searchHeadline
          }
        }
      }
    }
    setupStep
  }
  query JumpToLocationGroups {
    LocationGroups {
      objects {
        ...JumpToLocationGroup
      }
    }
  }
  query JumpToLocations {
    Locations {
      objects {
        ...JumpToLocation
      }
    }
  }
  query JumpToRoleGroups {
    RoleGroups {
      objects {
        ...JumpToRoleGroup
      }
    }
  }
  query JumpToRoles {
    Roles {
      objects {
        ...JumpToRole
      }
    }
  }
  query JumpToTags {
    Tags: AdminTags {
      ...Tag
    }
  }
  fragment JumpToEmployee on Employee {
    id
    name
    email
    phoneNumber
    userType
    deactivatedAt
    locations {
      id
      name
    }
  }
  fragment JumpToCourse on Course {
    id
    libraryItem {
      id
      name {
        ...TranslationSet
      }
      coverImage {
        ...CoverImage
      }
      searchHeadline
    }
  }
  fragment JumpToPath on Path {
    id
    libraryItem {
      id
      name {
        ...TranslationSet
      }
      coverImage {
        ...CoverImage
      }
      searchHeadline
    }
  }
  fragment JumpToTrainingResource on TrainingResource {
    id
    media {
      id
      mediaUrls {
        ...TranslationSet
      }
      imageUrls {
        id
        thumb
        original
      }
      unoptimizedUrl
    }
  }
  fragment JumpToSkill on Skill {
    id
    libraryItem {
      id
      name {
        ...TranslationSet
      }
      coverImage {
        ...CoverImage
      }
      searchHeadline
    }
  }
  fragment JumpToLocation on Location {
    id
    name
  }
  fragment JumpToRole on Role {
    id
    name
  }
  fragment JumpToLocationGroup on LocationGroup {
    id
    name
    type
    locationMemberships {
      id
    }
  }
  fragment JumpToRoleGroup on RoleGroup {
    id
    name
    roleMemberships {
      id
    }
  }
`;
