import AiAssistBadge from "@src/components/marketplace/AiAssistBadge";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import Button from "@src/deprecatedDesignSystem/components/Button";
import Modal from "@src/deprecatedDesignSystem/components/Modal";
import TitleHeader from "@src/deprecatedDesignSystem/components/TitleHeader";
import { StyleSheet } from "aphrodite";
import { FC, useCallback, useMemo, useState } from "react";
import AIGenerateUploadFiles from "./AIGenerateUploadFiles";
import AIGenerateDescribeModule from "./AIGenerateDescribeModule";
import AIGenerateUploadResources from "./AIGenerateUploadResources";
import Tab from "@src/deprecatedDesignSystem/components/Tab";
import Text from "@ui/text";
import { AIAssistModalTopSection } from "./AIAssistModalTopSection";
import { useAIAssistBrandSelector } from "../../../hooks/useAIAssistBrandSelector";
import AIUploadArticulateModal from "./AIUploadArticulateModal";
import { useFeatureFlag } from "@src/hooks/useFeatureFlag";

type Props = {
  closeModal: () => void;
  initialText?: string;
  loading: boolean;
  convertSCORMPackage: (key: string) => void;
  generateModuleFromTextList: (
    textList: string[],
    brandDetailsId: string,
  ) => void;
  generateModuleFromMedia: (
    mediaUrls: string[],
    brandDetailsId: string,
  ) => void;
  generateModuleFromTrainingResources: (
    ids: string[],
    brandDetailsId: string,
  ) => void;
  placeholder: string;
  question: string;
  buttonText: string;
  title: string;
};

export enum AIGenerateModalContent {
  UploadFiles = "Upload Files",
  DescribeModule = "Describe Module",
  UploadResources = "Select Resources",
  UploadArticulate = "Import Articulate 360",
}

const AIGenerateModalAIContext: FC<Props> = ({
  closeModal,
  initialText,
  loading,
  generateModuleFromTextList,
  generateModuleFromMedia,
  generateModuleFromTrainingResources,
  convertSCORMPackage,
  placeholder,
  question,
  buttonText,
  title,
}) => {
  const isArticulateEnabled = useFeatureFlag("articulate-import");
  const {
    brandSelectOptions,
    selectedAIBrandContextId: selectedBrandId,
    setSelectedAIBrandContextId: setSelectedBrandId,
    selectedBrandItem,
  } = useAIAssistBrandSelector();
  const [uploadedArticulateFileKey, setUploadedArticulateFileKey] = useState<
    string | null
  >(null);
  const [selectedTrainingResourceIds, setSelectedTrainingResourceIds] =
    useState<Set<string>>(new Set());
  const [text, setText] = useState(initialText || "");
  const [modalContent, setModalContent] = useState<AIGenerateModalContent>(
    AIGenerateModalContent.UploadFiles,
  );
  const isSubmitButtonDisabled = useMemo(() => {
    if (!loading && modalContent === AIGenerateModalContent.DescribeModule) {
      return !text;
    } else if (
      !loading &&
      modalContent === AIGenerateModalContent.UploadResources
    ) {
      return selectedTrainingResourceIds.size === 0;
    } else if (
      !loading &&
      modalContent === AIGenerateModalContent.UploadArticulate
    ) {
      return !uploadedArticulateFileKey;
    }
    return true;
  }, [
    text,
    selectedTrainingResourceIds,
    loading,
    modalContent,
    uploadedArticulateFileKey,
  ]);
  const handleClick = useCallback(() => {
    if (!selectedBrandItem) {
      return;
    }
    if (modalContent === AIGenerateModalContent.DescribeModule) {
      generateModuleFromTextList([text], selectedBrandItem?.brandDetailsId);
    } else if (modalContent === AIGenerateModalContent.UploadResources) {
      generateModuleFromTrainingResources(
        Array.from(selectedTrainingResourceIds),
        selectedBrandItem?.brandDetailsId,
      );
    } else if (modalContent === AIGenerateModalContent.UploadArticulate) {
      if (!uploadedArticulateFileKey) {
        return;
      }
      convertSCORMPackage(uploadedArticulateFileKey);
    }
  }, [
    selectedBrandItem,
    modalContent,
    generateModuleFromTextList,
    text,
    generateModuleFromTrainingResources,
    selectedTrainingResourceIds,
    uploadedArticulateFileKey,
    convertSCORMPackage,
  ]);
  if (!selectedBrandItem || !selectedBrandItem?.aiOrgContext) {
    return null;
  }
  return (
    <Modal
      styleDeclaration={styles.modal}
      header={
        <TitleHeader
          onCancelClick={closeModal}
          element={
            <AutoLayout
              flex={1}
              direction="horizontal"
              alignmentVertical="center"
            >
              <Text type="P1" fontWeight="SemiBold">
                {title}
              </Text>
              <AutoLayout styleDeclaration={styles.aiAssistBadge}>
                <AiAssistBadge />
              </AutoLayout>
            </AutoLayout>
          }
        />
      }
      footer={
        <AutoLayout
          direction="horizontal"
          spaceBetweenItems={15}
          alignmentHorizontal="right"
          padding={12}
          styleDeclaration={styles.footer}
        >
          <Button
            text={buttonText}
            leftIcon="sparkles-2"
            loading={loading}
            disabled={isSubmitButtonDisabled}
            onClick={() => {
              handleClick();
            }}
          />
        </AutoLayout>
      }
    >
      <AutoLayout
        flex={1}
        alignSelf="stretch"
        padding="0px 20px 20px 20px"
        direction="vertical"
        width={672}
        spaceBetweenItems={16}
      >
        <div />
        <AIAssistModalTopSection
          brandSelectOptions={brandSelectOptions}
          selectedBrandId={selectedBrandId}
          setSelectedBrandId={setSelectedBrandId}
          selectedBrandItem={selectedBrandItem}
        />

        <>
          <AutoLayout
            alignSelf="stretch"
            padding="8px 0px 16px 0px"
            spaceBetweenItems={24}
            alignmentHorizontal="center"
          >
            <Tab
              type="Underline"
              height="32px"
              text={AIGenerateModalContent.UploadFiles}
              active={modalContent === AIGenerateModalContent.UploadFiles}
              onClick={() => {
                setModalContent(AIGenerateModalContent.UploadFiles);
              }}
            />
            <Tab
              type="Underline"
              height="32px"
              text={AIGenerateModalContent.UploadResources}
              active={modalContent === AIGenerateModalContent.UploadResources}
              onClick={() => {
                setModalContent(AIGenerateModalContent.UploadResources);
              }}
            />
            <Tab
              type="Underline"
              height="32px"
              text={AIGenerateModalContent.DescribeModule}
              active={modalContent === AIGenerateModalContent.DescribeModule}
              onClick={() => {
                setModalContent(AIGenerateModalContent.DescribeModule);
              }}
            />
            {isArticulateEnabled && (
              <Tab
                type="Underline"
                height="32px"
                text={AIGenerateModalContent.UploadArticulate}
                active={
                  modalContent === AIGenerateModalContent.UploadArticulate
                }
                onClick={() => {
                  setModalContent(AIGenerateModalContent.UploadArticulate);
                }}
              />
            )}
          </AutoLayout>
          {modalContent === AIGenerateModalContent.UploadFiles && (
            <AIGenerateUploadFiles
              loading={loading}
              generateModuleFromMedia={(mediaUrls) =>
                generateModuleFromMedia(
                  mediaUrls,
                  selectedBrandItem.brandDetailsId,
                )
              }
            />
          )}
          {modalContent === AIGenerateModalContent.DescribeModule && (
            <AIGenerateDescribeModule
              text={text}
              setText={setText}
              question={question}
              placeholder={placeholder}
              loading={loading}
            />
          )}
          {modalContent === AIGenerateModalContent.UploadResources && (
            <AIGenerateUploadResources
              loading={loading}
              selectedTrainingResourceIds={selectedTrainingResourceIds}
              setSelectedTrainingResourceIds={setSelectedTrainingResourceIds}
            />
          )}
          {modalContent === AIGenerateModalContent.UploadArticulate && (
            <AIUploadArticulateModal
              convertSCORMPackage={convertSCORMPackage}
              loading={loading}
              uploadedArticulateFileKey={uploadedArticulateFileKey}
              setUploadedArticulateFileKey={setUploadedArticulateFileKey}
            />
          )}
        </>
      </AutoLayout>
    </Modal>
  );
};

const styles = StyleSheet.create({
  modal: {
    maxHeight: "min(95vh, 800px)",
  },
  gridChoice: {
    backgroundColor: deprecatedTones.blue2,
    borderRadius: 16,
    width: "100%",
  },
  choiceContainer: {
    border: `1px solid #EBEBEB`,
    borderRadius: 16,
    height: 278,
    width: "100%",
  },
  choiceInside: {
    position: "relative",
  },
  yMinLabel: {
    position: "absolute",
    bottom: "calc(100% + 21px)",
    left: "50%",
    transform: "translateX(-50%)",
  },
  yMaxLabel: {
    position: "absolute",
    top: "calc(100% + 21px)",
    left: "50%",
    transform: "translateX(-50%)",
  },
  xMinLabel: {
    position: "absolute",
    right: "calc(100% + 21px)",
    top: "50%",
    transform: "translateY(-50%)",
  },
  xMaxLabel: {
    position: "absolute",
    left: "calc(100% + 21px)",
    top: "50%",
    transform: "translateY(-50%)",
  },
  footer: {
    borderTop: "1px solid #EBEBEB",
  },
  aiAssistBadge: {
    padding: "6px 8px",
  },
});

export default AIGenerateModalAIContext;
