import { gql } from "@apollo/client";
import {
  EmployeeItem,
  JumpToBarLibraryItem,
  JumpToItem,
  LocationItem,
  RoleGroupItem,
  RoleItem,
} from "@components/ui/jumpToBar/hooks/useAllJumpToItems";
import {
  useRecentlyViewedEmployeesQuery,
  useRecentlyViewedLibraryItemsQuery,
  useRecentlyViewedLocationGroupsQuery,
  useRecentlyViewedLocationsQuery,
  useRecentlyViewedRoleGroupsQuery,
  useRecentlyViewedRolesQuery,
} from "@components/ui/jumpToBar/hooks/useAllRecentlyViewedItems.generated";
import { LibraryItemType } from "@src/types.generated";
import { getMediaUrl } from "@src/utils/media";
import { orderBy } from "lodash";
import { DateTime } from "luxon";
import { useMemo } from "react";

type Return = {
  items: JumpToItem[];
};

const useAllRecentlyViewedItems = (): Return => {
  const oneWeekAgo = useMemo(
    () => DateTime.now().minus({ weeks: 1 }).toISO(),
    [],
  );
  const variables = {
    openedStartDate: oneWeekAgo,
  };
  const { data: employeeData } = useRecentlyViewedEmployeesQuery({ variables });
  const { data: libraryItemsData } = useRecentlyViewedLibraryItemsQuery({
    fetchPolicy: "no-cache",
    variables: {
      input: {
        sort: { column: "openedAt", descending: true, nullsLast: true },
      },
      pagination: {
        limit: 10,
        offset: 0,
      },
    },
  });
  const { data: locationsData } = useRecentlyViewedLocationsQuery({
    variables,
  });
  const { data: rolesData } = useRecentlyViewedRolesQuery({ variables });
  const { data: locationGroupsData } = useRecentlyViewedLocationGroupsQuery({
    variables,
  });
  const { data: roleGroupsData } = useRecentlyViewedRoleGroupsQuery({
    variables,
  });
  const recentlyViewedItems = useMemo(() => {
    const employees: EmployeeItem[] =
      employeeData?.Employees.objects.map((employee) => {
        return {
          id: employee.id,
          name: employee.name || "",
          email: employee.email,
          phoneNumber: employee.phoneNumber,
          userType: employee.userType,
          openedAt: employee.openedAt,
          locations: employee.locations,
          type: "Employee",
        };
      }) || [];
    const libraryItems: JumpToBarLibraryItem[] = [];
    libraryItemsData?.LibraryItems.objects.forEach((li) => {
      if (li.type === LibraryItemType.Path && li.path) {
        libraryItems.push({
          id: li.path.id,
          libraryItemId: li.id,
          type: "Module",
          searchHeadline: li.searchHeadline,
          coverImage: li.coverImage || undefined,
          name: li.name.en,
          openedAt: li.openedAt,
        });
      } else if (li.type === LibraryItemType.Course && li.course) {
        libraryItems.push({
          id: li.course.id,
          libraryItemId: li.id,
          type: "Course",
          searchHeadline: li.searchHeadline,
          coverImage: li.coverImage || undefined,
          name: li.name.en,
          openedAt: li.openedAt,
        });
      } else if (
        li.type === LibraryItemType.TrainingResource &&
        li.trainingResource
      ) {
        libraryItems.push({
          id: li.trainingResource.id,
          libraryItemId: li.id,
          type: "Resource",
          searchHeadline: li.searchHeadline,
          mediaUrl: li.trainingResource.media
            ? getMediaUrl(li.trainingResource.media, { thumb: true })
            : undefined,

          name: li.name?.en || "",
          openedAt: li.openedAt,
        });
      } else if (li.type === LibraryItemType.Skill && li.skill) {
        libraryItems.push({
          id: li.skill.id,
          libraryItemId: li.id,
          type: "Skill",
          coverImage: li.coverImage || undefined,
          name: li.name.en,
          searchHeadline: li.searchHeadline,
          openedAt: li.openedAt,
        });
      } else if (li.orgPremiumContentConfig) {
        const catalogPath =
          li.orgPremiumContentConfig.catalogConfig?.catalogPath;
        if (catalogPath) {
          const path = catalogPath.path;

          libraryItems.push({
            id: li.orgPremiumContentConfig.id,
            type: "PremiumPath",
            catalogPathId: catalogPath.id,
            name: path.libraryItem.name.en,
            coverImage: path.libraryItem.coverImage || undefined,
            openedAt: li.openedAt,
            searchHeadline: path.libraryItem.searchHeadline,
          });
        }
      }
    });
    const locations: LocationItem[] =
      locationsData?.Locations.objects.map((location) => {
        return {
          id: location.id,
          name: location.name,
          openedAt: location.openedAt,
          type: "Location",
        };
      }) || [];
    const roles: RoleItem[] =
      rolesData?.Roles.objects.map((role) => {
        return {
          id: role.id,
          name: role.name,
          openedAt: role.openedAt,
          type: "Role",
        };
      }) || [];
    const locationGroups: JumpToItem[] =
      locationGroupsData?.LocationGroups.objects.map((locationGroup) => {
        return {
          id: locationGroup.id,
          name: locationGroup.name,
          type: locationGroup.type,
          openedAt: locationGroup.openedAt,
        };
      }) || [];
    const roleGroups: RoleGroupItem[] =
      roleGroupsData?.RoleGroups.objects.map((roleGroup) => {
        return {
          id: roleGroup.id,
          name: roleGroup.name,
          openedAt: roleGroup.openedAt,
          type: "RoleGroup",
        };
      }) || [];
    return orderBy(
      [
        ...employees,
        ...libraryItems,
        ...roles,
        ...roleGroups,
        ...locations,
        ...locationGroups,
      ],
      "openedAt",
      "desc",
    ).filter((x) => !!x.openedAt);
  }, [
    employeeData?.Employees.objects,
    libraryItemsData?.LibraryItems.objects,
    locationsData?.Locations.objects,
    rolesData?.Roles.objects,
    locationGroupsData?.LocationGroups.objects,
    roleGroupsData?.RoleGroups.objects,
  ]);

  return {
    items: recentlyViewedItems,
  };
};

export default useAllRecentlyViewedItems;

gql`
  query RecentlyViewedEmployees($openedStartDate: DateTime!) {
    Employees: AdminEmployees(openedStartDate: $openedStartDate) {
      objects {
        ...JumpToEmployee
        openedAt
      }
    }
  }
  query RecentlyViewedLibraryItems(
    $input: AdminLibraryInput!
    $pagination: PaginationInput
  ) {
    LibraryItems: AdminLibrary(input: $input, pagination: $pagination) {
      objects {
        id
        type
        path {
          id
        }
        course {
          id
        }
        trainingResource {
          id
        }
        skill {
          id
        }
        name {
          ...TranslationSet
        }
        searchHeadline
        coverImage {
          ...CoverImage
        }
        trainingResource {
          ...JumpToTrainingResource
        }
        orgPremiumContentConfig {
          ...JumpToOrgPremiumContentConfig
        }
        orgSharpConfig {
          id
        }
        openedAt
      }
    }
  }
  query RecentlyViewedCourses($openedStartDate: DateTime!) {
    CourseLibrary: AdminCourseLibrary(openedStartDate: $openedStartDate) {
      ...JumpToCourse
      libraryItem {
        id
        openedAt
      }
    }
  }
  query RecentlyViewedTrainingResources($openedStartDate: DateTime!) {
    TrainingResources: AdminTrainingResources(
      openedStartDate: $openedStartDate
    ) {
      ...JumpToTrainingResource
      libraryItem {
        id
        openedAt
      }
    }
  }
  query RecentlyViewedLocations($openedStartDate: DateTime!) {
    Locations(input: { openedStartDate: $openedStartDate }) {
      objects {
        ...JumpToLocation
        openedAt
      }
    }
  }
  query RecentlyViewedRoles($openedStartDate: DateTime!) {
    Roles(input: { openedStartDate: $openedStartDate }) {
      objects {
        ...JumpToRole
        openedAt
      }
    }
  }
  query RecentlyViewedLocationGroups($openedStartDate: DateTime!) {
    LocationGroups(input: { openedStartDate: $openedStartDate }) {
      objects {
        ...JumpToLocationGroup
        openedAt
      }
    }
  }
  query RecentlyViewedRoleGroups($openedStartDate: DateTime!) {
    RoleGroups(input: { openedStartDate: $openedStartDate }) {
      objects {
        ...JumpToRoleGroup
        openedAt
      }
    }
  }
`;
