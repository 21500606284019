import AIGenerateModalAIContext from "@src/components/modals/ai/AIGenerateModalAIAssist";
import {
  CreateAiModuleResultFragment,
  useCreateAiModuleMutation,
  useConvertScormPackageMutation,
} from "@components/modals/ai/AIGenerateModuleModal.generated";
import { useToast } from "@hooks/useToast";
import gql from "graphql-tag";
import { FC } from "react";
import router from "next/router";

type Props = {
  closeModal: () => void;
  onModuleCreated: (createdModule: CreateAiModuleResultFragment) => void;
};

const AIGenerateModuleModal: FC<Props> = ({ closeModal, onModuleCreated }) => {
  const { addErrorToast } = useToast();
  const [createAIModuleMutation, { loading: loadingCreateAIModule }] =
    useCreateAiModuleMutation({
      onCompleted: (data) => {
        if (data.createAIModule.success && data.createAIModule.path) {
          onModuleCreated(data.createAIModule.path);
          closeModal();
        } else {
          addErrorToast({
            callsite: "create_ai_module_modal",
          });
        }
      },
      onError: () => {
        addErrorToast({
          callsite: "create_ai_module_modal",
        });
      },
    });
  const [convertSCORMPackage, { loading: loadingConvertSCORMPackage }] =
    useConvertScormPackageMutation({
      onCompleted: (data) => {
        if (data.convertSCORMPackage.success) {
          closeModal();
          const libraryItemId =
            data?.convertSCORMPackage?.scormUpload?.pathVersion?.path
              .libraryItem.id;
          if (libraryItemId) {
            router.push({
              pathname: "/library/library-item/[id]",
              query: { id: libraryItemId },
            });
          }
        } else {
          addErrorToast({
            callsite: "convert_scorm_package",
          });
        }
      },
      onError: () => {
        addErrorToast({
          callsite: "convert_scorm_package",
        });
      },
    });
  return (
    <AIGenerateModalAIContext
      title="New Module"
      closeModal={closeModal}
      placeholder="Paste text here. For a high-quality module, at least several paragraphs works best"
      loading={loadingCreateAIModule || loadingConvertSCORMPackage}
      convertSCORMPackage={(key) => convertSCORMPackage({ variables: { key } })}
      generateModuleFromTrainingResources={(ids, brandDetailsId) =>
        createAIModuleMutation({
          variables: {
            input: {
              textList: [],
              trainingResourceUuids: ids,
              brandDetailsId,
            },
          },
        })
      }
      generateModuleFromTextList={(textList, brandDetailsId) =>
        createAIModuleMutation({
          variables: {
            input: {
              textList,
              brandDetailsId,
            },
          },
        })
      }
      generateModuleFromMedia={(mediaUrls, brandDetailsId) =>
        createAIModuleMutation({
          variables: {
            input: {
              textList: [],
              mediaUrls,
              brandDetailsId,
            },
          },
        })
      }
      question="What is your module about?"
      buttonText="Generate module"
    />
  );
};

gql`
  mutation CreateAIModule($input: AIModuleInput!) {
    createAIModule(input: $input) {
      success
      error {
        code
      }
      path {
        ...CreateAIModuleResult
      }
    }
  }

  fragment CreateAIModuleResult on Path {
    id
    draftVersion {
      id
    }
    libraryItem {
      id
    }
  }
  mutation ConvertSCORMPackage($key: String!) {
    convertSCORMPackage(key: $key) {
      scormUpload {
        id
        pathVersion {
          id
          path {
            id
            libraryItem {
              id
            }
          }
        }
      }
      success
      error {
        code
      }
    }
  }
`;

export default AIGenerateModuleModal;
