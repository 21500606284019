import { useCallback, useState } from "react";
import { FC } from "react";
import gql from "graphql-tag";
import axios from "axios";
import XIcon from "@src/ui/icons/18px/x";
import { useGetScormPackageUploadDataMutation } from "./AIUploadArticulateModal.generated";
import { useToast } from "@src/hooks/useToast";
import { Button } from "@src/ui/button";
import Spinner from "@src/deprecatedDesignSystem/components/Spinner";

type Props = {
  convertSCORMPackage: (key: string) => void;
  loading: boolean;
  uploadedArticulateFileKey: string | null;
  setUploadedArticulateFileKey: (key: string | null) => void;
};

const AIUploadArticulateModal: FC<Props> = ({
  convertSCORMPackage,
  loading,
  uploadedArticulateFileKey,
  setUploadedArticulateFileKey,
}) => {
  const { addErrorToast } = useToast();
  const [uploadedFileName, setUploadedFileName] = useState<string | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const [postingToS3, setPostingToS3] = useState(false);
  const [percentUploadComplete, setPercentUploadComplete] = useState(0);
  const postToS3 = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (data: any) => {
      if (!file) {
        addErrorToast({
          callsite: "ai_upload_articulate",
        });
        setPostingToS3(false);
        return;
      }
      const formData = new FormData();
      const key = data.fields.key;
      Object.entries(data.fields).forEach(([key, value]) => {
        formData.append(key, value as string);
      });
      formData.append("file", file);

      try {
        const response = await axios.post(data.url, formData, {
          headers: {},
          onUploadProgress: (progressEvent) => {
            if (!progressEvent.total) return;
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total,
            );
            setPercentUploadComplete(percentCompleted);
          },
        });
        if (response.status >= 200 && response.status < 300) {
          setPostingToS3(false);
          setUploadedArticulateFileKey(key);
          setUploadedFileName(file.name);
        } else {
          addErrorToast({
            callsite: "ai_upload_articulate",
          });
          setPostingToS3(false);
          setPercentUploadComplete(0);
        }
      } catch {
        addErrorToast({
          callsite: "ai_upload_articulate",
        });
      }
    },
    [addErrorToast, file, setUploadedArticulateFileKey, setUploadedFileName],
  );
  const [
    getSCORMPackageUploadURL,
    { loading: loadingGetSCORMPackageUploadURL },
  ] = useGetScormPackageUploadDataMutation({
    onCompleted: (data) => {
      if (
        data.getSCORMPackageUploadData.success &&
        data.getSCORMPackageUploadData.data
      ) {
        setPercentUploadComplete(0);
        setPostingToS3(true);
        postToS3(data.getSCORMPackageUploadData.data);
      } else {
        addErrorToast({
          callsite: "ai_upload_articulate",
        });
      }
    },
    onError: () => {
      addErrorToast({
        callsite: "ai_upload_articulate",
      });
    },
  });

  const onClick = useCallback(() => {
    if (uploadedArticulateFileKey) {
      convertSCORMPackage(uploadedArticulateFileKey);
    } else {
      const input = document.createElement("input");
      input.type = "file";
      input.accept = ".zip";
      input.onchange = (e) => {
        const file = (e.target as HTMLInputElement).files?.[0];
        if (file) {
          setFile(file);
          getSCORMPackageUploadURL({ variables: { key: file.name } });
        }
      };
      input.click();
    }
  }, [
    getSCORMPackageUploadURL,
    uploadedArticulateFileKey,
    convertSCORMPackage,
  ]);
  const clearUpload = useCallback(() => {
    setUploadedArticulateFileKey(null);
    setUploadedFileName(null);
    setFile(null);
  }, [setUploadedArticulateFileKey, setUploadedFileName, setFile]);
  return (
    <div className="flex w-full flex-col items-center justify-center gap-4 p-10">
      {postingToS3 && <>Upload {percentUploadComplete}% complete</>}
      {uploadedArticulateFileKey && uploadedFileName && (
        <div className="flex items-center gap-2">
          {uploadedFileName}{" "}
          <Button variant="ghost" size="xs" onClick={clearUpload}>
            <XIcon />
          </Button>
        </div>
      )}
      <Button
        onClick={onClick}
        disabled={
          loadingGetSCORMPackageUploadURL ||
          loading ||
          postingToS3 ||
          !!uploadedArticulateFileKey
        }
      >
        {postingToS3 ? <Spinner color="white" size={20} /> : "Upload"}
      </Button>
    </div>
  );
};

gql`
  mutation GetSCORMPackageUploadData($key: String!) {
    getSCORMPackageUploadData(key: $key) {
      data
      success
      error {
        code
      }
    }
  }
`;

export default AIUploadArticulateModal;
