import * as Types from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateAiModuleMutationVariables = Types.Exact<{
  input: Types.AiModuleInput;
}>;


export type CreateAiModuleMutation = { __typename?: 'Mutation', createAIModule: { __typename?: 'PathMutationResult', success: boolean, error?: { __typename?: 'Error', code: string } | null, path?: { __typename?: 'Path', id: number, draftVersion: { __typename?: 'PathVersion', id: string }, libraryItem: { __typename?: 'LibraryItem', id: string } } | null } };

export type CreateAiModuleResultFragment = { __typename?: 'Path', id: number, draftVersion: { __typename?: 'PathVersion', id: string }, libraryItem: { __typename?: 'LibraryItem', id: string } };

export type ConvertScormPackageMutationVariables = Types.Exact<{
  key: Types.Scalars['String'];
}>;


export type ConvertScormPackageMutation = { __typename?: 'Mutation', convertSCORMPackage: { __typename?: 'SCORMPackageConversionMutationResult', success: boolean, scormUpload?: { __typename?: 'SCORMUpload', id: string, pathVersion?: { __typename?: 'PathVersion', id: string, path: { __typename?: 'Path', id: number, libraryItem: { __typename?: 'LibraryItem', id: string } } } | null } | null, error?: { __typename?: 'Error', code: string } | null } };

export const CreateAiModuleResultFragmentDoc = gql`
    fragment CreateAIModuleResult on Path {
  id
  draftVersion {
    id
  }
  libraryItem {
    id
  }
}
    `;
export const CreateAiModuleDocument = gql`
    mutation CreateAIModule($input: AIModuleInput!) {
  createAIModule(input: $input) {
    success
    error {
      code
    }
    path {
      ...CreateAIModuleResult
    }
  }
}
    ${CreateAiModuleResultFragmentDoc}`;
export type CreateAiModuleMutationFn = Apollo.MutationFunction<CreateAiModuleMutation, CreateAiModuleMutationVariables>;

/**
 * __useCreateAiModuleMutation__
 *
 * To run a mutation, you first call `useCreateAiModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAiModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAiModuleMutation, { data, loading, error }] = useCreateAiModuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAiModuleMutation(baseOptions?: Apollo.MutationHookOptions<CreateAiModuleMutation, CreateAiModuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAiModuleMutation, CreateAiModuleMutationVariables>(CreateAiModuleDocument, options);
      }
export type CreateAiModuleMutationHookResult = ReturnType<typeof useCreateAiModuleMutation>;
export type CreateAiModuleMutationResult = Apollo.MutationResult<CreateAiModuleMutation>;
export type CreateAiModuleMutationOptions = Apollo.BaseMutationOptions<CreateAiModuleMutation, CreateAiModuleMutationVariables>;
export const ConvertScormPackageDocument = gql`
    mutation ConvertSCORMPackage($key: String!) {
  convertSCORMPackage(key: $key) {
    scormUpload {
      id
      pathVersion {
        id
        path {
          id
          libraryItem {
            id
          }
        }
      }
    }
    success
    error {
      code
    }
  }
}
    `;
export type ConvertScormPackageMutationFn = Apollo.MutationFunction<ConvertScormPackageMutation, ConvertScormPackageMutationVariables>;

/**
 * __useConvertScormPackageMutation__
 *
 * To run a mutation, you first call `useConvertScormPackageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConvertScormPackageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [convertScormPackageMutation, { data, loading, error }] = useConvertScormPackageMutation({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useConvertScormPackageMutation(baseOptions?: Apollo.MutationHookOptions<ConvertScormPackageMutation, ConvertScormPackageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConvertScormPackageMutation, ConvertScormPackageMutationVariables>(ConvertScormPackageDocument, options);
      }
export type ConvertScormPackageMutationHookResult = ReturnType<typeof useConvertScormPackageMutation>;
export type ConvertScormPackageMutationResult = Apollo.MutationResult<ConvertScormPackageMutation>;
export type ConvertScormPackageMutationOptions = Apollo.BaseMutationOptions<ConvertScormPackageMutation, ConvertScormPackageMutationVariables>;