import React from "react";
import { Popover, PopoverContent, PopoverTrigger } from "@src/ui/popover";
import { Textarea } from "@src/ui/textarea";
import { Button } from "@src/ui/button";
import { useCreateDashboardFeedbackMutation } from "@src/components/layout/top-nav/HelpButton.generated";
import { useToast } from "@hooks/useToast";
import { useTranslation } from "@src/utils/translationSets";
import useLanguage from "@src/hooks/useLanguage";
import { DropdownMenuItem } from "@src/ui/dropdown-menu";

const SUBMIT_FEEDBACK_ENGLISH = "Submit feedback";
const FEEDBACK_SUBMITTED_ENGLISH = "Feedback submitted";

export const SubmitFeedbackPopover: React.FC = () => {
  const [open, setOpen] = React.useState(false);
  const [feedback, setFeedback] = React.useState("");
  const { addErrorToast, addToast } = useToast();
  const language = useLanguage();
  const submitFeedbackTranslation = useTranslation(
    SUBMIT_FEEDBACK_ENGLISH,
    language || "en",
  );
  const feedbackSubmittedTranslation = useTranslation(
    FEEDBACK_SUBMITTED_ENGLISH,
    language || "en",
  );
  const [createDashboardFeedback, { loading }] =
    useCreateDashboardFeedbackMutation({
      onCompleted: (data) => {
        if (data) {
          addToast({
            message:
              feedbackSubmittedTranslation.text || FEEDBACK_SUBMITTED_ENGLISH,
          });
          setOpen(false);
        } else {
          addErrorToast({
            data,
            callsite: "dashboard_feedback_form",
          });
        }
        setFeedback("");
      },
    });
  const handleSubmit = React.useCallback(() => {
    createDashboardFeedback({ variables: { comment: feedback } });
  }, [createDashboardFeedback, feedback]);
  const timeoutRef = React.useRef<NodeJS.Timeout | null>(null);
  const handleOpenChange = (newOpen: boolean) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    if (!newOpen) {
      timeoutRef.current = setTimeout(() => {
        setOpen(false);
      }, 100);
    } else {
      setOpen(true);
    }
  };
  return (
    <Popover open={open} onOpenChange={handleOpenChange}>
      <PopoverTrigger asChild>
        <DropdownMenuItem
          onSelect={(event) => {
            event.preventDefault();
          }}
          onPointerEnter={() => handleOpenChange(true)}
        >
          {submitFeedbackTranslation.text || SUBMIT_FEEDBACK_ENGLISH}
        </DropdownMenuItem>
      </PopoverTrigger>
      <PopoverContent
        className="w-80"
        side="left"
        align="start"
        sideOffset={16}
        onPointerEnter={() => handleOpenChange(true)}
        onPointerLeave={() => handleOpenChange(false)}
      >
        <div className="space-y-3">
          <Textarea
            placeholder="Your feedback..."
            className="h-24"
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
          />
          <div className="flex justify-end">
            <Button
              onClick={handleSubmit}
              disabled={!feedback.trim() || loading}
            >
              {submitFeedbackTranslation.text || SUBMIT_FEEDBACK_ENGLISH}
            </Button>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
};
