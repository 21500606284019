import { JumpToItem } from "@components/ui/jumpToBar/hooks/useAllJumpToItems";
import Fuse from "fuse.js";
import { useMemo } from "react";

const MAX_ITEMS = 50;

const useSearchJumpToItems = (
  jumpToItems: JumpToItem[],
  searchValue: string,
): JumpToItem[] => {
  return useMemo(() => {
    if (searchValue) {
      return new Fuse(jumpToItems, fuseConfig)
        .search(searchValue)
        .map((m) => m.item)
        .slice(0, MAX_ITEMS);
    } else {
      return jumpToItems.slice(0, MAX_ITEMS);
    }
  }, [jumpToItems, searchValue]);
};

export default useSearchJumpToItems;

const fuseConfig = {
  threshold: 0.1,
  keys: ["name", "email", "phoneNumber", "searchHeadline"],
};
