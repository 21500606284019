import React from "react";
import Link from "next/link";
import {
  SidebarGroup,
  SidebarGroupContent,
  SidebarMenu,
  SidebarMenuItem,
  SidebarMenuButton,
  SidebarGroupLabel,
  SidebarGroupLabelIcon,
  useSidebar,
} from "@src/ui/sidebar";
import TagOutlineIcon from "@src/ui/icons/18px/tag-outline";
import BoltOutlineIcon from "@src/ui/icons/18px/bolt-outline";
import FileSendOutlineIcon from "@src/ui/icons/18px/file-send-outline";
import AsteriskIcon from "@src/ui/icons/18px/asterisk";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@src/ui/collapsible";
import { useRouter } from "next/router";
import { useBooleanLocalStorage } from "@src/hooks/useLocalStorage";
import useActionPermissions from "@src/hooks/useActionPermissions";
import { useFeatureFlag } from "@src/hooks/useFeatureFlag";
import { Ff } from "@src/types.generated";
import EmojiOutlineIcon from "@src/ui/icons/18px/emoji-outline";

const AdminLeftNavWorkflowsMenu: React.FC = () => {
  const { pathname } = useRouter();
  const { state } = useSidebar();
  const [isOpen, setIsOpen] = useBooleanLocalStorage(
    `admin-left-nav-workflows-menu-open`,
    true,
  );
  const { userActionPermissions } = useActionPermissions();
  const guestFeedbackEnabled = useFeatureFlag(Ff.GuestFeedbackCorrectiveAction);
  return (
    <Collapsible
      open={state === "collapsed" ? true : isOpen}
      onOpenChange={setIsOpen}
      asChild
      className="group/collapsible"
    >
      <SidebarGroup>
        <SidebarGroupLabel asChild>
          <CollapsibleTrigger>
            Workflows
            <SidebarGroupLabelIcon />
          </CollapsibleTrigger>
        </SidebarGroupLabel>
        <CollapsibleContent>
          <SidebarGroupContent>
            <SidebarMenu>
              <SidebarMenuItem>
                <SidebarMenuButton
                  asChild
                  isActive={pathname === "/tags"}
                  tooltip="Tags"
                >
                  <Link href="/tags">
                    <TagOutlineIcon />
                    Tags
                  </Link>
                </SidebarMenuButton>
              </SidebarMenuItem>
              {userActionPermissions?.manageLibrary &&
                userActionPermissions.updateAutomations && (
                  <SidebarMenuItem>
                    <SidebarMenuButton
                      asChild
                      isActive={pathname === "/automations"}
                      tooltip="Automations"
                    >
                      <Link href="/automations">
                        <BoltOutlineIcon />
                        Automations
                      </Link>
                    </SidebarMenuButton>
                  </SidebarMenuItem>
                )}
              <SidebarMenuItem>
                <SidebarMenuButton
                  asChild
                  isActive={pathname === "/paths"}
                  tooltip="Paths"
                >
                  <Link href="/paths">
                    <AsteriskIcon />
                    Paths
                  </Link>
                </SidebarMenuButton>
              </SidebarMenuItem>
              {userActionPermissions?.manageLibrary &&
                userActionPermissions.updateAutomations && (
                  <SidebarMenuItem>
                    <SidebarMenuButton
                      asChild
                      isActive={pathname === "/access"}
                      tooltip="Access"
                    >
                      <Link href="/access">
                        <FileSendOutlineIcon />
                        Access
                      </Link>
                    </SidebarMenuButton>
                  </SidebarMenuItem>
                )}
              {guestFeedbackEnabled && (
                <SidebarMenuItem>
                  <SidebarMenuButton
                    asChild
                    isActive={pathname === "/guest-feedback"}
                    tooltip="Guest Feedback"
                  >
                    <Link href="/guest-feedback">
                      <EmojiOutlineIcon />
                      Guest Feedback
                    </Link>
                  </SidebarMenuButton>
                </SidebarMenuItem>
              )}
            </SidebarMenu>
          </SidebarGroupContent>
        </CollapsibleContent>
      </SidebarGroup>
    </Collapsible>
  );
};

export default AdminLeftNavWorkflowsMenu;
