import * as Types from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetScormPackageUploadDataMutationVariables = Types.Exact<{
  key: Types.Scalars['String'];
}>;


export type GetScormPackageUploadDataMutation = { __typename?: 'Mutation', getSCORMPackageUploadData: { __typename?: 'SCORMPackageUploadDataMutationResult', data?: { [key: string]: any } | null, success: boolean, error?: { __typename?: 'Error', code: string } | null } };


export const GetScormPackageUploadDataDocument = gql`
    mutation GetSCORMPackageUploadData($key: String!) {
  getSCORMPackageUploadData(key: $key) {
    data
    success
    error {
      code
    }
  }
}
    `;
export type GetScormPackageUploadDataMutationFn = Apollo.MutationFunction<GetScormPackageUploadDataMutation, GetScormPackageUploadDataMutationVariables>;

/**
 * __useGetScormPackageUploadDataMutation__
 *
 * To run a mutation, you first call `useGetScormPackageUploadDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetScormPackageUploadDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getScormPackageUploadDataMutation, { data, loading, error }] = useGetScormPackageUploadDataMutation({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useGetScormPackageUploadDataMutation(baseOptions?: Apollo.MutationHookOptions<GetScormPackageUploadDataMutation, GetScormPackageUploadDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetScormPackageUploadDataMutation, GetScormPackageUploadDataMutationVariables>(GetScormPackageUploadDataDocument, options);
      }
export type GetScormPackageUploadDataMutationHookResult = ReturnType<typeof useGetScormPackageUploadDataMutation>;
export type GetScormPackageUploadDataMutationResult = Apollo.MutationResult<GetScormPackageUploadDataMutation>;
export type GetScormPackageUploadDataMutationOptions = Apollo.BaseMutationOptions<GetScormPackageUploadDataMutation, GetScormPackageUploadDataMutationVariables>;